<template>
    <div class="main">
        <transition name="flip">
            <section class="section orange py-5">
                <div class="container">
                    <div class="row match-height">
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/kundli">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/kundli.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Kundli</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/kundli-matchmaking">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/kundli-matchmaking.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Matchmaking</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/rudraksh-gemstone">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/rudraksh-gemstone.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Rudraksh & Gemstone</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/numerology">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/numerology.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Numerology</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/shubh-kal">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/shubh-kal.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Auspicious Time</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/auspicious-days">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/auspicious-days.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Auspicious Days</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/festivals">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/festivals.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Festivals</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/panchang">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/panchang.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Panchang</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/horoscope">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/horoscopes.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Horoscopes</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/zodiac-match">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/compatibilty.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Zodiac Compatibilty</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/pujas">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/pujas.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Pujas & Mantras</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/blogs">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/blogs.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Blogs</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/astro-pujas">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/astro-pujas.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Puja Bookings</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <router-link to="/astrologers">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/chat.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Chat</div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <div class="cursor-pointer" @click="emitter.emit('showAVmodal')">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/audio_call.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Audio Call</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 col-md-4">
                            <div class="service-box mb-4">
                                <div class="overlay"></div>
                                <div class="content">
                                    <div class="cursor-pointer" @click="emitter.emit('showAVmodal')">
                                        <div class="wrapper">
                                            <img src="/app-assets/images/services/video_call.png" class="img-fluid rounded-circle" alt="Service Logo">
                                        </div>
                                        <div class="title">Video Call</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            mobile: this.isMobile(),
        };
    },
    mounted() {
        const items = this.$el.querySelectorAll('.service-box');
        for (var i = 0; i < items.length; i++) {

            ['mouseenter', 'mouseleave'].forEach((eventname) => {
            items[i].addEventListener(eventname, (event) => {

                var dir = this.getHoverDirection(event);
                event.currentTarget.classList.remove('mouseenter');
                event.currentTarget.classList.remove('mouseleave');
                event.currentTarget.classList.remove('top');
                event.currentTarget.classList.remove('right');
                event.currentTarget.classList.remove('bottom');
                event.currentTarget.classList.remove('left');
                event.currentTarget.className += ' ' + event.type + ' ' + dir;
            }, false);
            });
        }
    },
    methods: {
        getHoverDirection(event) {
            var directions = ['top', 'right', 'bottom', 'left'];
            var item = event.currentTarget;
            var w = item.offsetWidth;
            var h = item.offsetHeight;

            var x = (event.clientX - item.getBoundingClientRect().left - (w / 2)) * (w > h ? (h / w) : 1);
            var y = (event.clientY - item.getBoundingClientRect().top - (h / 2)) * (h > w ? (w / h) : 1);
            var d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
            return directions[d];
        },
    },
}
</script>