<template>
    <div class="main">
        <section class="section orange pt-4" ref="section">
            <!-- <div v-if="!loading" class="animated-background"></div> -->
            <div class="container">
                <div class="row">
                    <transition name="grow" mode="out-in">
                        <div class="col-lg-8 offset-lg-2" v-if="loading">
                            <loader :text="'Loading. . .'" />
                        </div>
                        <div v-else-if="!loading && err" class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10 offset-lg-1" v-else-if="!loading && !err && result">
                            <button type="button" class="btn orange-dark large text-uppercase mx-auto mb-5">Rudraksh-Gemstone Suggestion Result</button>
                            <transition-group tag="div" class="row" name="flip">
                                <div class="col-12" v-if="result.length" :key="result[0]">
                                    <div class="card outline">
                                        <div class="d-flex flex-column gap-2">
                                            <h5 class="title text-capitalize">Rudraksh Suggestion<hr></h5>
                                            <h6 class="mb-1 mt-3">Rudraksh Name</h6>
                                            <p class="description">{{ result[0].name }}</p>
                                            <h6 class="mb-1 mt-3">Short Read</h6>
                                            <p class="description">{{ result[0].mukhi_description }}</p>
                                            <h6 class="mb-1 mt-3">Recommendation</h6>
                                            <p class="description">{{ result[0].bot_response }}</p>
                                            <div class="mb-1 mt-3 flex-sc gap-3">
                                                <h6>Mukhi for Wealth: </h6>
                                                <p class="description">{{ result[0].mukhi_for_money }}</p>
                                            </div>
                                            <div class="mb-1 mt-3 flex-sc gap-3">
                                                <h6>Mukhi for Health: </h6>
                                                <p class="description">{{ result[0].mukhi_for_disease_cure }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" v-if="result.length" :key="result[1]">
                                    <div class="card outline">
                                        <div class="d-flex flex-column">
                                            <h5 class="title text-capitalize">Gemstone Suggestion<hr></h5>
                                            <div class="row">
                                                <div class="col-lg-9 order-2 order-lg-1">
                                                    <h6 class="mt-3 mb-1">Recommended to Wear: </h6>
                                                    <p class="description">{{ result[1].name }}</p>
                                                    <h6 class="mt-3 mb-1">Other Name: </h6>
                                                    <p class="description">{{ result[1].other_name }}</p>
                                                </div>
                                                <div class="col-lg-3 order-1 order-lg-2 text-center" v-if="result[1].key">
                                                    <img :src="'/app-assets/images/stones/'+result[1].key+'.png'" :class="{'w-50' : mobile}" :alt="result[1].key+' - Gemstone Image'">
                                                </div>
                                            </div>
                                            <h6 class="mb-1 mt-3">Description: </h6>
                                            <p class="description">{{ result[1].description }}</p>
                                            <h6 class="mt-3 mb-1">Benifits: </h6>
                                            <p class="description">{{ result[1].good_results.join(', ') }}</p>
                                            <h6 class="mt-3 mb-1">Diseases Cure: </h6>
                                            <p class="description">{{ result[1].diseases_cure.join(', ') }}</p>
                                            <h6 class="mt-3 mb-1">Best Time to Wear: </h6>
                                            <p class="description">{{ result[1].time_to_wear_short }}</p>
                                            <h6 class="mb-1 mt-3">Method to Wear: </h6>
                                            <p class="description">{{ result[1].methods }}.</p>
                                            <h6 class="mb-1 mt-3">Metal to wear with: </h6>
                                            <p class="description">{{ result[1].metal }}</p>
                                            <h6 class="mb-1 mt-3">Weight to Wear: </h6>
                                            <p class="description">{{ result[1].weight }}</p>
                                            <h6 class="mb-1 mt-3">Finger: </h6>
                                            <p class="description">{{ result[1].finger }}</p>
                                            <h6 class="mt-3 mb-1">Mantra: </h6>
                                            <p class="description">{{ result[1].mantra }}</p>
                                            <h6 class="mb-3 mt-3">Flaw Results: </h6>
                                            <div class="row">
                                                <div class="col-lg-8">
                                                    <table class="table kundli-table text-capitalize">
                                                        <tbody>
                                                            <tr v-for="r in result[1].flaw_results">
                                                                <td>{{ r.flaw_type }}</td>
                                                                <td>{{ r.flaw_effects }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            result: [],
            data: [],
            loading: false,
            err: false,
       };
    },
    created() {
        if(this.token) {
            this.id = this.$route.params.id
            this.index()
        } else this.loading = true
    },
    computed: {
        array_fields() {
            return Object.keys(this.result).filter(key => typeof this.result[key] === 'object' && this.result[key] !== null && !Array.isArray(this.result[key]));
        },
    },
    methods: {
        index() {
            this.loading = true
            axios.post('/api/user/get-gemstone-suggestion-result', {query: this.id}, this.config).then((res) => {
                this.loading = false
                if(res.data.status == 'success') {
                    this.data = res.data.data
                    this.result = Object.values(res.data.result) 
                } else {
                    this.err = res.data.message
                }
                this.$nextTick(() => {
                    this.emitter.emit('scroll_section')
                    // this.emitter.emit('animate_background')
                })
            }).catch((err) => {
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                this.loading = false
            })
        },
    }
}
</script>
<style scoped>
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.grow-enter-active, .grow-leave-active {
    transition: all 0.5s ease-out;
}
.grow-enter-from {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
.grow-leave-to {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
</style>