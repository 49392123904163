<template>
    <div class="main">
        <section class="section" ref="section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-3">
                        <div class="card outline sticky p-lg-4" data-top="5%">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <div class="container-fluid" :class="{'d-block': !mobile}">
                                    <div class="flex-bc w-100 border-bottom pb-2 gap-2">
                                        <h6 class="pb-1">Filter</h6>
                                        <button class="btn small navbar-toggler" type="button" @click="showFilter=!showFilter">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </div>
                                    <transition name="slide-down">
                                        <div class="collapse navbar-collapse flex-column pt-3 d-block" v-if="!mobile || showFilter">
                                            <div class="w-100 form-group pb-3">
                                                <input type="search" v-model="search" @input="debouncedSearch" class="form-control" placeholder="Search here" />
                                            </div>
                                            <h6 class="w-100">
                                                Categories
                                                <small class="float-end cursor-pointer" v-if="category.length" @click="category=[]"><i>Clear</i></small>
                                                <hr>
                                            </h6>
                                            <div class="w-100 form-group">
                                                <v-select :options="categories" v-model="category" @option:selected="index" multiple :placeholder="'Select one to filter'" class="small-height h-auto bg-white" :clearable="false"></v-select>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <transition-group name="scale" appear mode="out-in" tag="div" class="row match-height">
                            <div class="col-md-6 col-12 col-lg-4" v-for="(blog,index) in blogs" :key="index">
                                <blog-card :blog="blog"></blog-card>
                            </div>
                            <nav key="Page navigation" aria-label="Page navigation" v-if="blogs.length">
                                <ul class="custom-pagination" v-if="pagination?.pageCount > 7">
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==1 }" @click="pageNo=1"><a class="page-link" href="#">1</a></li>
                                <li class="divider" :class="{ 'active' : pageNo > 1 && pageNo < pagination?.pageCount }"></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==pagination?.pageCount }" @click="pageNo = pagination?.pageCount"><a class="page-link" href="#">{{ pagination?.pageCount }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                                <ul class="custom-pagination" v-else>
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{'active' : pageNo == i}" v-for="i in pagination?.pageCount" @click="pageNo=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                            </nav>
                        </transition-group>
                        <transition name="fade">
                            <loader v-if="loading && !err" :text="loader_text" />
                            <div class="col-lg-8 offset-lg-2" v-else-if="!loading && err">
                                <div class="card">
                                    <div class="text-center row match-height">
                                        <div class="col-lg-6 spin-mandala ty">
                                            <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                            <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                        </div>
                                        <div class="col-lg-6" v-if="!mobile">
                                            <img src="/app-assets/images/avatar.png" alt="Mandala">
                                        </div>
                                    </div>
                                    <p class="desc">Oops, {{ err }}</p>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pagination: null,
            pageNo: 1,
            search: '',
            category: [],
            showFilter: false,
            loading: false,
            loader_text: 'Loading . . .',
            blogs: [],
            err: false,
            categories: [],
            mobile: this.isMobile()
        };
    },
    created() {
      this.debouncedSearch = this.$debounce(this.index, 500);
      this.index();
    },
    watch: {
        pageNo: {
            handler(value) {
                this.index();
            },
            immediate: true
        },
    },
    methods: {
        index() {
            this.err = false;
            this.loading = true;
            axios.post(`/api/user/blogs/get?page=${this.pageNo}`, {category: this.category, search: this.search}).then(res => {
                this.blogs = res.data.blogs.data
                this.categories = res.data.cats;
                this.pagination = {
                    page: res.data.blogs.current_page,
                    pageSize: res.data.blogs.per_page,
                    pageCount: res.data.blogs.last_page,
                    total: res.data.blogs.total
                };
                this.loading = false;
                this.emitter.emit('scroll_section')
                if(!res.data.blogs.data.length)
                this.err = 'No Records Found.'
            }).catch((err) => {
                this.loading = false
                this.err = 'Server Error Occured!'
            });
        }
    },
};
</script>
<style scoped>
    .scale-move,.scale-enter-active, .scale-leave-active {
        transition: all 0.4s ease;
        transform-origin: center;
    }
    .scale-enter-from, .scale-leave-to {
        transform: scale(0);
        filter: blur(10px);
        opacity: 0;
    }
    .slide-down-enter-active, .slide-down-leave-active {
        transition: all 0.2s linear;
        transform-origin: center top;
    }
    .slide-down-enter-from, .slide-down-leave-to {
        transform: scale(0);
        filter: blur(10px);
    }
</style>