import './bootstrap';
import { createApp, ref, defineAsyncComponent } from 'vue';
import UserLayout from './components/user/user-layout.vue';
import { Form } from 'vform'
import Swal from 'sweetalert2'
import { createRouter, createWebHistory } from 'vue-router'
import mitt from 'mitt';
import moment from 'moment'
const emitter = mitt();
import VueSelect from 'vue-select'
import VueDatePicker from '@vuepic/vue-datepicker';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import fb_msg from './firebase.js'
import gsap from 'gsap'
import routes from './routes'
import StarRating from 'vue-star-rating'
import globalFunctions from './functions.js';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
})

window.ref = ref
window.Form = Form
window.db = fb_msg.db
window.ref = fb_msg.ref
window.fireStorage = fb_msg.storage
window.Swal = Swal
window.moment = moment
window.toast = Toast
window.gsap = gsap

const Vue = createApp();

Vue.component('v-select', VueSelect);
Vue.component('star-rating', StarRating);
Vue.component('user-layout', UserLayout);
Vue.component('VueDatePicker', VueDatePicker);

Vue.component('talk-box', defineAsyncComponent(() => import('./components/user/partials/talk-box.vue')));
Vue.component('loader', defineAsyncComponent(() => import('./components/user/partials/loader.vue')));
Vue.component('talk-section', defineAsyncComponent(() => import('./components/user/partials/talk-section.vue')));
Vue.component('astrologer-card', defineAsyncComponent(() => import('./components/user/partials/astrologer-card.vue')));
Vue.component('log-card', defineAsyncComponent(() => import('./components/user/partials/log-card.vue')));
Vue.component('puja-card', defineAsyncComponent(() => import('./components/user/partials/puja-card.vue')));
Vue.component('blog-card', defineAsyncComponent(() => import('./components/user/partials/blog-card.vue')));
Vue.component('blog-section', defineAsyncComponent(() => import('./components/user/partials/blog-section.vue')));
Vue.component('horoscope-section', defineAsyncComponent(() => import('./components/user/partials/horoscope-section.vue')));
Vue.component('app-rooms-section', defineAsyncComponent(() => import('./components/user/partials/app-rooms-section.vue')));
Vue.component('live-stream-section', defineAsyncComponent(() => import('./components/user/partials/live-stream-section.vue')));
Vue.component('error-page', defineAsyncComponent(() => import('./components/user/partials/error-page.vue')));
Vue.component('l-pagination', defineAsyncComponent(() => import('./components/user/partials/pagination.vue')));

Vue.config.globalProperties.emitter = emitter
Vue.config.globalProperties.$messaging = fb_msg.messaging
Vue.use(globalFunctions);
Vue.use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VITE_MAPS_API_KEY,
        libraries: "places",
        loading: 'async',
        v: 3.56,
    },
})

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) { return savedPosition }
        else { return { top: 0, behavior: 'smooth' } }
    }
})

function isLogged() {
    return localStorage.getItem('userLogin') !== undefined ? localStorage.getItem('userLogin') : false;
}

function page_title (slug) {
    switch (slug) {
        case 'tnc': return 'Terms & Conditions' ;
        case 'privacy-policy': return 'Privacy Policy' ;
        case 'refund-policy': return 'Refund Policy' ;
        case 'shipping-policy': return 'Shipping Policy' ;
        case 'disclaimer': return 'Disclaimer' ;
        case 'cancellation-policy': return 'Cancellation Policy' ;
        default: break;
    }
}

router.beforeEach((to, from, next) => {
    if (to.name == 'policyPage') {
        to.meta.title = page_title(to.params.slug);
    }
    if (to.matched.some(record => record.meta.auth)) {
        if (isLogged()) next()
        else {
            next('/')
            // Toast.fire({
            //     icon: "info",
            //     title: 'Login to Continue',
            // });
            emitter.emit('showlogin')
        }
    }
    else {
        if (to.matched.some(record => record.meta.both)) {
            next()
        }
        else {
            if (isLogged()) next('/')
            else next()
        }
    }
});
Vue.use(router)
Vue.mount('#root');
export { emitter };