<template>
    <div class="main">
        <section class="section blog-details-wrapper">
            <div class="container">
                <transition name="fade">
                    <loader v-if="loading" :text="loader_text"  />
                    <div v-else-if="!loading && !err" class="row">
                        <div class="col-lg-8 mt-5 mt-sm-0 order-2 order-lg-1">
                            <form @submit.prevent="save()" class="card orange">
                                <div class="col-12 form-group">
                                    <p class="info"><b>Note: </b>Thank you for connecting with our Astrologer on a {{ log.type == 'audio' ? 'Audio' : 'Video' }} call. We hope you had an enlightening session and that all your questions were answered satisfactorily.</p>
                                </div>
                                <div class="col-12 col-lg-6 form-group orange">
                                    <label>Rate Your Experience with the Astrologer</label>
                                    <star-rating v-model:rating="review.stars_by_user" :increment="0.5" :active-color="'#38210F'"></star-rating>
                                </div>
                                <div class="col-12 col-lg-6 form-group orange">
                                    <label>Rate the {{ log.type == 'audio' ? 'Audio' : 'Video' }} Quality of the Call</label>
                                    <star-rating v-model:rating="review[log.type == 'audio' ? 'user_audio_quality' : 'user_video_quality']" :increment="0.5" :active-color="'#38210F'"></star-rating>
                                </div>
                                <div class="col-12 form-group orange">
                                    <label for="">Share Your Feedback or Suggestions</label>
                                    <textarea class="form-control" v-model="review.user_remarks" rows="2"></textarea>
                                </div>
                                <div class="col-12 col-lg-4 form-group orange">
                                    <button type="submit" :disabled="disBtn == 1" class="btn dark block">
                                        <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="!disBtn">Submit Review</span>
                                            <span v-else>Loading . . .</span>
                                        </transition>
                                    </button>
                                </div>
                                <div class="col-12">
                                    <p class="info"><b>Note: </b>Your feedback helps us improve our services and ensures we provide the best possible experience to all our customers.</p>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-4 order-1 order-lg-2">
                            <div class="card profile-card w-100 mt-0">
                                <router-link :to="'/astrologer/'+log.pandit.username" class="text-body">
                                    <div class="content">
                                        <div class="photo">
                                            <img :src="log.pandit.photo || '/app-assets/images/h-logo.png'" alt="">
                                        </div>
                                        <div class="name">{{ log.pandit.name }}, <span class="gender text-capitalize">{{ log.pandit.gender }}</span></div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="card">
                                <div class="col-12 text-center form-group">
                                    <h4 class="mb-4"><u>{{ log.type }} Call Review</u></h4>
                                </div>
                                <div class="flex-bc gap-3 mb-3">
                                    <label>Session Type</label>
                                    <h6 :class="log.free_session ? 'text-success' : 'text-danger'">{{ log.free_session ? 'Free' : 'Paid' }}</h6>
                                </div>
                                <div class="flex-bc gap-3 mb-3"><label>Status</label><h6 class="text-capitalize">{{ log.status }}</h6></div>
                                <div class="flex-bc gap-3 mb-3"><label>Rate</label><h6>{{ log.currency.symbol }}{{ log.call_price }}/min</h6></div>
                                <div class="flex-bc gap-3 mb-3"><label>Duration</label><h6>{{ log.duration_label }}</h6></div>
                                <div class="flex-bc gap-3 mb-3"><label>Deduction</label><h6>{{ log.currency.symbol }}{{ log.total_price }}</h6></div>
                                <div class="flex-cc gap-3">
                                    <h6 class="mt-2">{{ $filters.timestamp(log.created_at) }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="!loading && err">
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            log: null,
            review: null,
            errors: [],
            err: null,
            loading: false,
            disBtn: false,
            loader_text: 'Loading . . .',
        };
    },
    created() {
        this.id = this.$route.params.id
        this.index()
    },
    methods: {
        index(flag = false) {
            if(!flag) this.loading = true
            axios.get('/api/user/call-review/'+this.id, this.config).then((res) => {
                if(!flag) {
                    this.loading = false
                    this.emitter.emit('scroll_section')
                }
                if(res.data.status == 'success') {
                    this.log = res.data.log
                    this.review = res.data.review
                } else {
                    this.err = res.data.message
                }

            }).catch((error) => {
                this.err = 'Server Error Occured!'
                if(!flag) this.loading = false
            })
        },
        async save() {
            this.disBtn = 1 ;
            await axios.post('/api/user/save-call-review/'+this.review.crypt_id, this.review, this.config).then((res) => {
                this.disBtn = false ;
                if(res.data.status == 'success') {
                    this.$router.push('/astro-wallet?type=call_logs')
                }
                else {
                    toast.fire({'icon': 'error', 'title':  res.data.message})
                }
            }).catch((e) => {
                if (e.response.status === 422) this.errors = e.response.data.errors;
                this.disBtn = false ;
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
            })
        },
    }
}
</script>