<template>
    <section class="section orange" ref="section">
        <!-- <div class="animated-background"></div> -->
        <div class="container">
            <div class="row">
                <transition name="fade" mode="out-in">
                    <div class="col-12" v-if="!loading">
                        <div class="card header">
                            <div class="card-header">
                                <div class="left-content">
                                    <i class="fad fa-wallet fa-2x"></i>
                                    <span>WALLET<br>BALANCE</span>
                                </div>
                                <div class="right-content">
                                    <h5>
                                        <transition name="fade" mode="out-in">
                                            <span :key="user.wallet_amount">{{ price_val(user.currency.code,user.wallet_amount) }}</span>
                                        </transition>
                                    </h5>
                                </div>
                            </div>
                            <div class="card-body" :class="{'px-2' : mobile}">
                                <h6 class="mb-4 text-body text-uppercase">Add Money to wallet</h6>
                                <form @submit.prevent="add_money()" class="row">
                                    <div class="col-lg-8 form-group">
                                        <div class="dropbox">
                                            <h6>Popular Topups<hr></h6>
                                            <div class="content" :class="{ 'flex-nowrap scroll-x-container p-0 gap-1' : mobile}">
                                                <div class="checks" v-for="(i,index) in topups" :key="index">
                                                    <input type="radio" @click="add_amount = i" name="topups" :id="'check_'+index">
                                                    <label :for="'check_'+index">{{ i }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4 flex-sc align-items-start flex-column orange required">
                                        <div class="form-group input-group" :class="{'w-auto': !mobile}">
                                            <div class="input-group-append">
                                                <button type="button" class="btn orange-dark left">{{ user?.currency?.symbol }}</button>
                                            </div>
                                            <input id="amount" type="tel" placeholder="Enter Amount" @keypress="validate" v-model="add_amount" pattern="[0-9]+(\.[0-9]{1})?%?" class="form-control" required>
                                        </div>
                                        <button :disabled="disBtn == 'add'" type="submit" class="btn dark block">
                                            <i v-if="disBtn == 'add'" class="fas fa-spinner fa-spin"></i>
                                            <transition name="flip" mode="out-in">
                                                <span v-if="disBtn == 'add'">Loading . . .</span>
                                                <span v-else>Add Money</span>
                                            </transition>
                                        </button>
                                    </div>
                                    <transition name="grow">
                                        <div class="col-12 form-group" v-if="show_promos && mobile">
                                            <div class="card mb-2">
                                                <form @submit.prevent="apply_promocode()" class="row">
                                                    <div class="col-12 col-lg-4">
                                                        <div class="form-group input-group">
                                                            <input type="text" v-model="promocode" placeholder="Enter Promocode" class="form-control" required>
                                                            <div class="input-group-append">
                                                                <button type="submit" :disabled="disBtn == 'apply'" class="btn dark">Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </form>
                                                <h5>Promocodes Available</h5>
                                                <div class="scroll-x-container">
                                                    <div class="promo" v-for="i in 5" :key="i">{{ i }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </form>
                                <transition name="grow">
                                    <div class="card my-4" v-if="show_promos && !mobile">
                                        <form @submit.prevent="apply_promocode()" class="row">
                                            <div class="col-12 col-lg-4">
                                                <div class="form-group input-group">
                                                    <input type="text" v-model="promocode" placeholder="Enter Promocode" class="form-control" required>
                                                    <div class="input-group-append">
                                                        <button type="submit" :disabled="disBtn == 'apply'" class="btn dark">Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                        </form>
                                        <h5>Promocodes Available</h5>
                                        <div class="scroll-x-container">
                                            <div class="promo" v-for="i in 5" :key="i">{{ i }}</div>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-10 offset-lg-1">
                                <ul class="nav custom-tabs">
                                    <li :class="{'active' : type=='transactions'}" @click="type='transactions'">Wallet Transactions</li>
                                    <li :class="{'active' : type=='call_logs'}" @click="type='call_logs'">Call Logs</li>
                                    <li :class="{'active' : type=='chat_logs'}" @click="type='chat_logs'">Chat Logs</li>
                                    <li class="border"></li>
                                </ul>
                            </div>
                        </div>
                        <transition-group name="fade" appear>
                            <div class="card header pt-3" v-if="type == 'transactions'">
                                <div class="card-footer d-block" :class="{'px-1' : mobile}">
                                    <table class="table transaction-table" v-if="!mobile">
                                        <thead>
                                            <tr>
                                                <th>Transaction Type</th>
                                                <th>Amount</th>
                                                <th>Transaction On</th>
                                                <th>status</th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <transition-group name="grow" appear>
                                                <tr v-for="(t,index) in transactions" :key="index">
                                                    <td><div class="transaction-td">
                                                        <div class="icon">
                                                            <img :src="'/app-assets/images/services/'+images(t.sub_type)" alt="Transaction logo">
                                                        </div>
                                                        <div class="content flex-cc">
                                                            <span v-if="t.type=='credit'" class="text-uppercase p-title">{{ t.notes }}</span>
                                                            <span v-else class="text-uppercase p-title">{{ t.sub_type?.replace('_', ' ') }}</span>
                                                            <!-- <span class="t-id">Transaction ID: {{ t.payment_id }}</span> -->
                                                        </div>
                                                    </div></td>
                                                    <td>{{ t.type=='credit' ? '+':'-' }}{{ price_val(t.currency.code,t.amount) }}</td>
                                                    <td>{{ $filters.timestamp(t.created_at) }}</td>
                                                    <td class="text-capitalize">{{ t.status }}</td>
                                                    <td><router-link :to="'/transaction/'+t.crypt_id" class="btn small dark">View</router-link></td>
                                                </tr>
                                            </transition-group>
                                            <tr v-if="!transactions.length">
                                                <td colspan="5"><div class="transaction-td d-block">
                                                    <div class="text-uppercase text-center p-title">No Transactions were found!</div>
                                                </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="table transaction-table" v-else>
                                        <tbody>
                                            <transition-group name="grow" appear>
                                                <tr v-for="(t,index) in transactions" :key="index">
                                                    <td><div class="transaction-td">
                                                        <div>
                                                            <div class="icon">
                                                                <img :src="'/app-assets/images/services/'+images(t.sub_type)" alt="Transaction logo">
                                                            </div>
                                                        </div>
                                                        <div class="content">
                                                            <span v-if="t.type=='credit'" class="text-uppercase p-title">{{ t.notes }}</span>
                                                            <span v-else class="text-uppercase p-title">{{ t.sub_type?.replace('_', ' ') }}</span>
                                                            <span class="text-uppercase">{{ $filters.timestamp(t.created_at) }}</span>
                                                            <!-- <span class="t-id">Txn ID: {{ t.payment_id }}</span> -->
                                                        </div>
                                                        <div class="amount">
                                                            {{ t.type=='credit' ? '+':'-' }}{{ price_val(t.currency.code,t.amount) }}
                                                        </div>
                                                    </div></td>
                                                </tr>
                                            </transition-group>
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-12">
                                            <!-- <l-pagination :paginator="pagination" :mobile="mobile" :pageName="'wallet'" v-if="transactions.length" /> -->
                                            <nav key="Page navigation" aria-label="Page navigation" v-if="transactions.length">
                                                <ul class="custom-pagination" v-if="pagination_1?.pageCount > 8 || mobile">
                                                    <li class="page-item prev-item" @click="pageNo_1 = (pageNo_1 > 1) ? pageNo_1 - 1 : pagination_1?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                                    <li class="page-item" :class="{ 'active' : pagination_1.page==1 }" @click="pageNo_1=1"><a class="page-link" href="#">1</a></li>
                                                    <li class="divider" :class="{ 'active' : pagination_1.page > 1 && pageNo_1 < pagination_1?.pageCount }"></li>
                                                    <li class="page-item" :class="{ 'active' : pagination_1.page==pagination_1?.pageCount }" @click="pageNo_1 = pagination_1?.pageCount"><a class="page-link" href="#">{{ pagination_1?.pageCount }}</a></li>
                                                    <li class="page-item next-item" @click="pageNo_1 = pageNo_1 < pagination_1?.pageCount ? pageNo_1+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                                </ul>
                                                <ul class="custom-pagination" v-else>
                                                    <li class="page-item prev-item" @click="pageNo_1 = (pageNo_1 > 1) ? pageNo_1 - 1 : pagination_1?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                                    <li class="page-item" :class="{'active' : pagination_1.page == i}" v-for="i in pagination_1?.pageCount" @click="pageNo_1=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                                    <li class="page-item next-item" @click="pageNo_1 = pageNo_1 < pagination_1?.pageCount ? pageNo_1+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row match-height" v-if="type == 'call_logs'">
                                <transition-group name="grow" appear>
                                    <div class="col-lg-4" v-for="(c,index) in call_logs" :key="index">
                                        <log-card type="call_log" :log="c" />
                                    </div>
                                    <div class="col-12" key="navigation">
                                        <nav key="Page navigation" aria-label="Page navigation" v-if="call_logs.length">
                                            <ul class="custom-pagination" v-if="pagination_2?.pageCount > 8 || mobile">
                                                <li class="page-item prev-item" @click="pageNo_2 = (pageNo_2 > 1) ? pageNo_2 - 1 : pagination_2?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                                <li class="page-item" :class="{ 'active' : pagination_2.page==1 }" @click="pageNo_2=1"><a class="page-link" href="#">1</a></li>
                                                <li class="divider" :class="{ 'active' : pagination_2.page > 1 && pageNo_2 < pagination_2?.pageCount }"></li>
                                                <li class="page-item" :class="{ 'active' : pagination_2.page==pagination_2?.pageCount }" @click="pageNo_2 = pagination_2?.pageCount"><a class="page-link" href="#">{{ pagination_2?.pageCount }}</a></li>
                                                <li class="page-item next-item" @click="pageNo_2 = pageNo_2 < pagination_2?.pageCount ? pageNo_2+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                            </ul>
                                            <ul class="custom-pagination" v-else>
                                                <li class="page-item prev-item" @click="pageNo_2 = (pageNo_2 > 1) ? pageNo_2 - 1 : pagination_2?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                                <li class="page-item" :class="{'active' : pagination_2.page == i}" v-for="i in pagination_2?.pageCount" @click="pageNo_2=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                                <li class="page-item next-item" @click="pageNo_2 = pageNo_2 < pagination_2?.pageCount ? pageNo_2+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </transition-group>
                            </div>
                            <div class="row match-height" v-if="type == 'chat_logs'">
                                <transition-group name="grow" appear>
                                    <div class="col-lg-4" v-for="(c,index) in chat_logs" :key="index">
                                        <log-card type="chat_log" :log="c" />
                                    </div>
                                    <div class="col-12" key="navigation">
                                        <nav key="Page navigation" aria-label="Page navigation" v-if="chat_logs.length">
                                            <ul class="custom-pagination" v-if="pagination_3?.pageCount > 8 || mobile">
                                                <li class="page-item prev-item" @click="pageNo_3 = (pageNo_3 > 1) ? pageNo_3 - 1 : pagination_3?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                                <li class="page-item" :class="{ 'active' : pagination_3.page==1 }" @click="pageNo_3=1"><a class="page-link" href="#">1</a></li>
                                                <li class="divider" :class="{ 'active' : pagination_3.page > 1 && pageNo_3 < pagination_3?.pageCount }"></li>
                                                <li class="page-item" :class="{ 'active' : pagination_3.page==pagination_3?.pageCount }" @click="pageNo_3 = pagination_3?.pageCount"><a class="page-link" href="#">{{ pagination_3?.pageCount }}</a></li>
                                                <li class="page-item next-item" @click="pageNo_3 = pageNo_3 < pagination_3?.pageCount ? pageNo_3+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                            </ul>
                                            <ul class="custom-pagination" v-else>
                                                <li class="page-item prev-item" @click="pageNo_3 = (pageNo_3 > 1) ? pageNo_3 - 1 : pagination_3?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                                <li class="page-item" :class="{'active' : pagination_3.page == i}" v-for="i in pagination_3?.pageCount" @click="pageNo_3=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                                <li class="page-item next-item" @click="pageNo_3 = pageNo_3 < pagination_3?.pageCount ? pageNo_3+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </transition-group>
                            </div>
                        </transition-group>
                    </div>
                    <div class="col-12" v-else>
                        <loader></loader>
                    </div>
                </transition>
            </div>
        </div>
    </section>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}

.fade-enter-from, .fade-leave-to {
    filter: blur(3px);
    opacity: 0.5;
}
.grow-enter-active, .grow-leave-active {
    transition: all 0.25s ease-out;
}
.grow-enter-from {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
.grow-leave-to {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>
<script>
export default {
    props: ['user'],
    name: 'Wallet',
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            errors: [],
            transactions: [],
            chat_logs: [],
            call_logs: [],
            type: 'transactions',
            show_promos: false,
            promocode: '',
            add_amount: '',
            disBtn: false,
            loading: false,
            pagination_1: null,
            pagination_2: null,
            pagination_3: null,
            pageNo_1: 1,
            pageNo_2: 1,
            pageNo_3: 1,
            topups: ['51', '101', '201', '501', '1001', '3001', '5001'],
        };
    },
    created() {
        this.emitter.emit('talk_section', true)
        this.emitter.emit('blog_section', true)

        var sc = document.createElement("script");
        sc.setAttribute("src", "https://checkout.razorpay.com/v1/checkout.js");
        sc.setAttribute("type", "text/javascript");
        document.head.appendChild(sc);

        // this.$nextTick(() => {
        //     this.emitter.emit('animate_background')
        // })
        this.emitter.on('page-changed', (data) => {
            if(data[1] == 'wallet')
                this.pageNo = data[0]
        })
        if(this.$route.query.type) this.type = this.$route.query.type
        this.index()
    },
    activated() {
        switch (this.type) {
            case 'transactions':
                this.fetch_change(this.type, this.pageNo_1) 
                break;
            case 'call_logs':
                this.fetch_change(this.type, this.pageNo_2) 
                break;
            case 'chat_logs':
                this.fetch_change(this.type, this.pageNo_3) 
                break;
            default:
                break;
        }
    },
    beforeUnmount() {
        this.emitter.emit('talk_section', false)
        this.emitter.emit('blog_section', false)
    },
    watch: {
        pageNo_1 (n,o) { 
            this.fetch_change(this.type, this.pageNo_1) 
        },
        pageNo_2 (n,o) { 
            this.fetch_change(this.type, this.pageNo_2) 
        },
        pageNo_3 (n,o) { 
            this.fetch_change(this.type, this.pageNo_3) 
        },
    },
    methods: {
        index() {
            axios.get('/api/user/wallet-transactions', this.config).then((res) => { 
                this.transactions = res.data.transactions.data
                this.call_logs = res.data.call_logs.data
                this.chat_logs = res.data.chat_logs.data
                this.pagination_1 = {
                    "page": res.data.transactions.current_page,
                    "pageSize": res.data.transactions.per_page,
                    "pageCount": res.data.transactions.last_page,
                    "total": res.data.transactions.total
                }
                this.pagination_2 = {
                    "page": res.data.call_logs.current_page,
                    "pageSize": res.data.call_logs.per_page,
                    "pageCount": res.data.call_logs.last_page,
                    "total": res.data.call_logs.total
                }
                this.pagination_3 = {
                    "page": res.data.chat_logs.current_page,
                    "pageSize": res.data.chat_logs.per_page,
                    "pageCount": res.data.chat_logs.last_page,
                    "total": res.data.chat_logs.total
                }
                // this.emitter.emit('scroll_section')
                // this.transactions = res.data.transactions
            }).catch(error => toast.fire({'icon': 'error', 'title': 'Error Occured!'})); 
        },
        fetch_change(type, pageNo) {
            axios.get(`/api/user/wallet-transactions?page=${pageNo}&type=${type}`, this.config).then((res) => { 
                this[type] = res.data[type].data
                var paginator = {
                    "page": res.data[type].current_page,
                    "pageSize": res.data[type].per_page,
                    "pageCount": res.data[type].last_page,
                    "total": res.data[type].total
                }
                switch (type) {
                    case 'transactions': this.pagination_1 = paginator ; break;
                    case 'call_logs': this.pagination_2 = paginator ; break;
                    case 'chat_logs': this.pagination_3 = paginator ; break;
                    default: break;
                }
                // this.emitter.emit('scroll_section')
                // this.transactions = res.data.transactions
            }).catch(error => toast.fire({'icon': 'error', 'title': 'Error Occured!'})); 
        },
        apply_promocode(code = null) {
            if(!code) code = this.promocode
            console.log(code);
        },
        add_money(amount = null) {
            this.disBtn = 'add'
            if(!amount) amount = this.add_amount
            var options = {
                "amount": this.add_amount*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": this.user.currency.code,
                "key": import.meta.env.VITE_RZP_KEY,
                "name": this.$appname,
                "description": this.$appname,
                "handler": (response) => {
                    axios.post('/api/user/payment-success', {'order_id': response.razorpay_payment_id }, this.config).then((res) => { 
                        this.emitter.emit('get_user')
                        this.disBtn = false
                        this.index()
                        this.add_amount = ''
                    }).catch((error) => {
                        console.log(error);
                        this.disBtn = false
                        toast.fire({'icon': 'error', 'title': 'Error Occured!'})
                    }); 
                },
                "modal": {
                    "ondismiss": () => {
                        toast.fire({'icon': 'info', 'title': 'Payment Cancelled!'})
                        this.disBtn = false
                    }
                },  
                "prefill": {
                    "email": this.user.email,
                    "contact": this.user.mobile,
                },
                "theme": {
                    "color": "#4c2d15"
                }
            };
            var rzp = new Razorpay(options);
            rzp.on('payment.failed', function (response){
                this.disBtn = false
                toast.fire({'icon': 'error', 'title': 'Payment Failed!'})
            });
            rzp.open();
        },
        images(type) {
            switch (type) {
                case 'kundli' : return 'kundli.png' ;
                case 'kundli_pdf' : return 'kundli.png' ;
                case 'matchmaking' : return 'kundli-matchmaking.png' ;
                case 'matchmaking_pdf' : return 'kundli-matchmaking.png' ;
                case 'rudraksh_gemstone' : return 'rudraksh-gemstone.png' ;
                case 'numerology' : return 'numerology.png' ;
                case 'chat' : return 'chat.png' ;
                case 'audio_call' : return 'audio_call.png' ;
                case 'video_call' : return 'video_call.png' ;
                case 'puja_booking' : return 'astro-pujas.png' ;
                case null : return 'money_add.png' ;
                default: break;
            }
        },
    }
}
</script>