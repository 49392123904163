<template>
    <div class="main">
        <transition-group name="fade">
            <section v-if="sign > -1" class="section yellow mt-3 pt-5" ref="scrollTo">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-10 offset-lg-1">
                            <div class="row">
                                <div class="col-3 col-xl-2">
                                    <div class="img-wrapper">
                                        <transition name="fade">
                                            <img :key="sign" :src="'/app-assets/images/zodiacs/'+(sign+1)+'_'+horoscopes[sign]+'.png'" :alt="horoscopes[sign]+' icon'">
                                        </transition>
                                    </div>
                                </div>
                                <div class="col-9 col-xl-3 flex-sc">
                                    <div class="flex-cc align-items-start gap-2 flex-column">
                                        <transition name="fade">
                                            <h5 :key="horoscopes[sign]">{{ horoscopes[sign] }} Horoscope</h5>
                                        </transition>
                                        <transition name="fade" mode="out-in">
                                            <h6 :key="tab_text">{{ tab_text }}</h6>
                                        </transition>
                                    </div>
                                </div>
                                <transition-group name="fade">
                                    <div class="col-10 offset-1 offset-sm-0 col-md-6 col-xl-4 flex-sc" v-if="!loading && tab < 5">
                                        <div class="form-group mx-auto mt-5 orange">
                                            <label class="mb-3"><h6>Choose Your Horoscope Sign</h6></label>
                                            <v-select class="w-100" :options="types" :clearable="false" v-model="type" :reduce="i => i.value"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-10 offset-1 offset-sm-0 col-md-6 col-xl-3 flex-sc" v-if="!loading">
                                        <div class="form-group mx-auto mt-5 orange">
                                            <label class="mb-3"><h6>Choose Your Language</h6></label>
                                            <v-select class="w-100" :options="api_langs" :clearable="false" v-model="lang" :reduce="i => i.value"></v-select>
                                        </div>
                                    </div>
                                </transition-group>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section v-if="sign > -1" class="section orange pt-5 horoscope-page">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-10 offset-lg-1">
                            <ul class="result-nav mb-5 pb-4">
                                <li :class="{'active' : tab == 1}" @click="tab=1">Yesterday</li>
                                <li :class="{'active' : tab == 2}" @click="tab=2">Today</li>
                                <li :class="{'active' : tab == 3}" @click="tab=3">Tomorrow</li>
                                <li :class="{'active' : tab == 4}" @click="tab=4">Weekly</li>
                                <li :class="{'active' : tab == 5}" @click="tab=5">{{ year }}</li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div v-if="!loading && tab < 5">
                                <div v-for="(r,i) in response?.bot_response" class="card outline">
                                    <div class="row">
                                        <div class="col-lg-2">
                                            <h6 class="text-uppercase pt-2 flex-md-column flex-bc gap-4 mb-4 mb-sm-0"><span>{{ i }}</span><span>{{ r.score }}</span></h6>
                                        </div>
                                        <div class="col-lg-10">
                                            <p class="description">{{ r.split_response }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="!loading && tab == 5">
                                <div v-for="(r,i) in response" class="mb-5">
                                    <h5>Phase {{ i+1 }}: {{ r.period }}<hr></h5>
                                    <div class="mt-2"><b>Score: </b>{{ r.score }}<br></div>
                                    <div class="mt-2 mb-5"><b>Prediction: </b>{{ r.prediction }}<br></div>
                                    <div class="card outline" v-for="a,index in filteredKeys(r)">
                                        <h6 class="text-uppercase pt-2 flex-bc gap-4 mb-4"><span>{{ index }}</span><span>{{ a.score }}</span></h6>
                                        <p class="description">{{ a.prediction }}</p>
                                        <div class="row">
                                            <div class="col-lg-2">
                                            </div>
                                            <div class="col-12">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <transition name="fade">
                                <loader v-if="loading" :text="loader_text"  />
                            </transition>
                        </div>
                    </div>
                </div>
            </section>
        </transition-group>
        <section class="section yellow py-5">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1">
                        <div class="horoscope-container">
                            <router-link :to="'/horoscope/'+h.toLowerCase()" v-for="(h,i) in horoscopes" :key="i" class="horoscope" :class="{'inactive' : i != sign || !sign}">
                                <div class="img-wrapper">
                                    <img :src="'/app-assets/images/zodiacs/'+(i+1)+'_'+h+'.png'" :alt="h+' icon'">
                                </div>
                                <div class="name">{{ h }}</div>
                                <div class="date">{{ dates[i] }}</div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user','api_langs'],
    data() {
        return {
            horoscopes: ['Aries','Taurus','Gemini','Cancer','Leo','Virgo','Libra','Scorpio','Sagittarius','Capricorn','Aquarius','Pisces'],
            dates: ['Mar 21-Apr 19','Apr 20-May 20','May 21-Jun 21','Jun 22-Jul 22','Jul 23-Aug 22','Aug 23-Sep 22','sep 23-Oct 22','Oct 23-Nov 21','Nov 22-Dec 21','Dec 22-Jan 19','Jan 20-Feb 18','Feb 19-Mar 20'],
            sign: -1,
            tab: 2,
            year: '',
            response: null,
            loading: false,
            type: 1,
            attributes: ["education","status","finances","relationship","career","travel","family","friends","health"],
            loader_text: 'Loading . . .',
            types: [{
                value: 1,
                label: 'Sun Sign',
            },{
                value: 2,
                label: 'Moon Sign',
            }],
            lang: this.user?.language || 'en',
        };
    },
    created() {
        if(this.$route.params.sign) {
            this.sign = this.horoscopes.findIndex(i => i.toLowerCase() == this.$route.params.sign)
            this.year = new Date().getFullYear()
        }
    },
    computed: {
        tab_text() {
            switch (this.tab) {
                case 1: return moment().subtract(1, 'day').format('ddd - DD MMM YYYY') ; break;
                case 2: return moment().format('ddd - DD MMM YYYY') ; break;
                case 3: return moment().add(1, 'day').format('ddd - DD MMM YYYY') ; break;
                case 4: return 'This Week' ; break;
                case 5: return 'Year - ' + moment().format('YYYY') ; break;
                default: break;
            }
        },
    },
    watch: {
        '$route' : function(n, o) {
            this.sign = this.horoscopes.findIndex(i => i.toLowerCase() == n.params.sign)
            this.year = new Date().getFullYear()
        },
        sign(n,o) {
            this.index()
        },
        tab(n,o) {
            this.index()
        },
        type(n,o) {
            this.index()
        },
        lang(n,o) {
            this.index()
        },
    },
    methods: {
        index() {
            this.loading = true
            this.loader_text = 'Loading . . .'
            var data = {
                tab: this.tab,
                sign: this.sign,
                type: this.type,
                lang: this.lang,
            } 
            axios.post('/api/user/horoscope/get', data).then((res)  => {
                if(this.tab < 5) this.response = res.data.response
                else this.response = Object.values(res.data.response)
                this.loading = false
                this.$refs.scrollTo.scrollIntoView({behaviour: 'smooth'});
            }).catch((err) => {
                this.loader_text = 'Error Occured'
            })
        },
        filteredKeys(obj) {
            return Object.fromEntries(
                Object.entries(obj).filter(([key]) => !["score", "period", "prediction"].includes(key))
            );
        },
    }
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s linear;
}
.fade-enter-from, .fade-leave-to {
    filter: blur(5px) ;
    opacity: 0 ;
}
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>