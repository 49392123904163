<template>
    <div class="main">
        <section class="section blog-details-wrapper">
            <div class="container">
                <transition name="fade">
                    <loader v-if="loading" :text="loader_text"  />
                    <div v-else-if="!loading && !err" class="row">
                        <div class="col-lg-8 mt-5 mt-sm-0 order-2 order-lg-1">
                            <form @submit.prevent="save()" class="card orange">
                                <div class="col-12 form-group">
                                    <p class="info"><b>Note: </b>We are pleased that your puja was successfully completed as scheduled. We hope the experience was meaningful and fulfilling for you.</p>
                                </div>
                                <div class="col-12 col-lg-6 form-group orange">
                                    <label>Overall Puja Experience</label>
                                    <star-rating v-model:rating="review.customer_stars" :increment="0.5" :active-color="'#38210F'"></star-rating>
                                </div>
                                <div class="col-12 form-group orange">
                                    <label for="">Remarks</label>
                                    <textarea class="form-control" v-model="review.remarks" rows="2"></textarea>
                                </div>
                                <div class="col-12 col-lg-4 form-group orange">
                                    <button type="submit" :disabled="disBtn == 1" class="btn dark block">
                                        <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="!disBtn">Submit Review</span>
                                            <span v-else>Loading . . .</span>
                                        </transition>
                                    </button>
                                </div>
                                <div class="col-12">
                                    <p class="info"><b>Note: </b>Your feedback helps us improve our services and ensure a better experience for everyone.</p>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-4 order-1 order-lg-2">
                            <div class="card profile-card w-100 mt-0">
                                <div class="content">
                                    <div class="photo">
                                        <img :src="booking.pandit?.pandit?.photo || '/app-assets/images/h-logo.png'" alt="">
                                    </div>
                                    <div class="name">{{ booking.pandit?.pandit?.name }}, <span class="gender text-capitalize">{{ booking.pandit?.pandit?.gender }}</span></div>
                                </div>
                            </div>
                            <div class="card orange">
                                <div class="col-12 text-center form-group">
                                    <h4><u>Puja Review</u></h4>
                                    <img src="/app-assets/images/puja-thali.png" class="w-50">
                                </div>
                                <div class="col-12 form-group orange" v-if="booking.type == 'onsite'">
                                    <label>Puja Location</label>
                                    <input type="text" class="form-control" readonly :value="booking.location.city">
                                </div>
                                <div class="flex-bc gap-3 mb-3">
                                    <label>At Price</label>
                                    <h6>{{ booking.currency_details.symbol+booking.price }} <p class="small-text d-inline" v-if="booking.status == 3">(Deducted)</p></h6>
                                </div>
                                <div class="flex-bc gap-3 mb-3">
                                    <label>Status</label>
                                    <h6>{{ status }}</h6>
                                </div>
                                <div class="flex-bc gap-3 mb-3">
                                    <label>Puja Type</label>
                                    <h6>{{ booking.type == 'online' ? 'Online Puja' : 'Onsite Puja' }}</h6>
                                </div>
                                <div class="flex-cc gap-3">
                                    <h6 class="mt-2">{{ $filters.date(booking.date) + ' ' + $filters.timeFT(booking.time) }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="!loading && err">
                        <div class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            booking: null,
            review: null,
            errors: [],
            err: null,
            loading: false,
            disBtn: false,
            loader_text: 'Loading . . .',
        };
    },
    created() {
        this.id = this.$route.params.id
        this.index()
    },
    computed: {
        status () {
            if(this.booking.status == 0) return 'Pending' ;
            else if(this.booking.status == 1) return 'Cancelled - By User' ;
            else if(this.booking.status == 2) return 'Cancelled - By Admin' ;
            else if(this.booking.status == 3) return 'Approved' ;
            else if(this.booking.status == 4) return 'Completed' ;
        }
    },
    methods: {
        index(flag = false) {
            if(!flag) this.loading = true
            axios.get('/api/user/booking-review/'+this.id, this.config).then((res) => {
                if(!flag) {
                    this.loading = false
                    this.emitter.emit('scroll_section')
                }
                if(res.data.status == 'success') {
                    this.booking = res.data.booking
                    this.review = res.data.review
                } else {
                    this.err = res.data.message
                }

            }).catch((error) => {
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                // this.$router.push('/my-bookings')
                if(!flag) this.loading = false
            })
        },
        async save() {
            this.disBtn = 1 ;
            await axios.post('/api/user/save-review/'+this.review.crypt_id, this.review, this.config).then((res) => {
                this.disBtn = false ;
                if(res.data.status == 'success') {
                    this.$router.push('/my-bookings')
                }
                else {
                    toast.fire({'icon': 'error', 'title':  res.data.message})
                }
            }).catch((e) => {
                if (e.response.status === 422) this.errors = e.response.data.errors;
                this.disBtn = false ;
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
            })
        },
    }
}
</script>